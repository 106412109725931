<template>
  <section class=" px-3 my-10">
    <div class='impressum'><h1 class="text-xl bold pb-2">Impressum</h1>
      <h1>Angaben nach § 5 TMG, § 36 VSBG und § 2 DL-InfoV</h1>
      <p><span><strong>TUR ADVOCATES Rechtsanwaltsgesellschaft mbH</strong></span></p>
      <p>Königstraße 53<br/>D- 32427 Minden<br/>Germany</p>
      <p>&nbsp;</p>
      <p>Tel: +49 571-73075540<br/>Fax:+49 571-73075549</p>
      <p>Email: <a>law@tur-advocates.com</a></p>
      <p>Web: <a>www.tur-advocates.com</a></p>
      <p>&nbsp;</p>
      <p>Niederlassung Berlin</p>
      <p>Hohenzollerndamm 56<br/>D- 14199 Berlin<br/>Germany<br/><br/>Tel: +49 30 549 060 990
        <br/>Fax:+49
        30 549 060999</p>
      <p>USt-Nr. DE326971205<br/><br/><strong>Verantwortlicher für den Inhalt der Webseite &amp;
        Impressum</strong><br/><br/>Herr Anthony Tur, Geschäftsführer<br/>E-Mail:
        <a>law@tur-advocates.com</a>
      </p>
      <p><a href="mailto:law@tur-advocates.com"></a><br/><strong>Handelsregister</strong>
        <br/>Amtsgericht
        Bad Oeynhausen<br/>HRB 17805 B<br/><br/>Geschäftsführer &amp; Gesellschafter<br/>
        Rechtsanwalt
        Anthony Tur<br/><br/><span style="font-size: 14pt;"><strong>Bürozeiten:</strong></span>
        <br/>Montag
        – Donnerstag<br/>09:00 Uhr - 16:30 Uhr (CET/ MEZ)<br/><br/>Freitag<br/>10:00 (CET/MEZ) –
        13:00 (CET/MEZ)<br/><br/>Um Terminabsprache wird gebeten.<br/><br/><br/><br/><br/>
        <strong>Zulassungsbehörden:</strong>
      </p>
      <p><br/>Gesetzliche Berufsbezeichnung:<br/>Rechtsanwaltsgesellschaft<br/><br/>Staat, in dem
        die Berufsbezeichnung verliehen wurde:<br/>Bundesrepublik Deutschland
        <br/><br/>Zulassung:<br/>In der Bundesrepublik Deutschland<br/><br/>Zuständige Kammer und
        Aufsichtsbehörde: <br/>Rechtsanwaltskammer für den Oberlandesgerichtsbezirk Hamm<br/>
        (Körperschaft des öffentlichen Rechts)<br/><br/> Ostenallee 18<br/>59063 Hamm<br/>
        Deutschland<br/><br/> Telefon: 02381 / 985000<br/>Telefax: 02381 /
        985050<br/>E-Mail:&nbsp;<span id="cloak6ec26449e461e64ffbf1e1a3ff2b8107"><a
          href="mailto:info@rak-hamm.de"></a></span><span
          id="cloak9d1d222d91e6f13478b95d861aadadb2"><a href="mailto:info@rak-hamm.de">
          info@rak-hamm.de</a></span><br/><br/>
        Internet:<a href="https://www.rechtsanwaltskammer-hamm.de/"></a><a
          href="https://www.rechtsanwaltskammer-hamm.de/">http://www.rechtsanwaltskammer-hamm.de
        </a><br/><br/><strong>Professional
          Title/ Berufsbezeichnung</strong><br/><br/>Barrister (England &amp; Wales)<br/>Bar
        Standards Board / Bar Council of England &amp; Wales:<br/>Bar Standards Board</p>
      <p><strong>Bar Standards Board</strong><br/>289-293 High Holborn<br/>London<br/>WC1V 7HZ
        <br/>DX:
        240 LDE<br/><br/>Tel:&nbsp; +44 (0)20 7611 1444<br/>Fax: +44 (0)20 7831 9217<br/>Website:
        <a href="https://www.barstandardsboard.org.uk/header-items/contact-us/">
          https://www.barstandardsboard.org.uk/header-items/contact-us/</a><br/>Bar
        Council of England &amp; Wales<br/>The Bar Council<br/><br/>289-293 High Holborn<br/>London
        WC1V 7HZ<br/>DX: 240 LDE<br/><br/>Tel: 020 7242 0082<br/>Fax: 020 7831 9217<br/>Website: <a
          href="http://www.barcouncil.org.uk/contact-us/">http://www.barcouncil.org.uk/contact-us/
        </a><br/>Barristers
        Online Register <br/>&nbsp;</p>
      <h6>B</h6>
      Berufsbezeichnung:
      <p>Anthony Tur führt die gesetzliche Berufsbezeichnung „Rechtsanwalt“. Diese Berufsbezeichnung
        wurde in der Bundesrepublik Deutschland verliehen.</p>
      <p>Berufsbezeichnung:</p>
      <p>Liam Pender führt die gesetzliche Berufsbezeichnung „Rechtsanwalt“. Diese Berufsbezeichnung
        wurde in der Bundesrepublik Deutschland verliehen.</p>
      <p><strong>Rechtsanwaltskammer Hamm</strong><br/>Ostenallee 18<br/>59063 Hamm<br/>
        Deutschland<br/><br/>
        Telefon: 02381 / 985000<br/>Telefax: 02381 / 985050<br/>E-Mail:;<span><a
          href="mailto:info@rak-hamm.de"></a></span><span><a href="mailto:info@rak-hamm.de">
          info@rak-hamm.de</a></span><br/><br/>
        Internet:<a href="https://www.rechtsanwaltskammer-hamm.de/"></a><a
          href="https://www.rechtsanwaltskammer-hamm.de/">http://www.rechtsanwaltskammer-hamm.de</a>
        <br/><br/><strong>Berufsrechtliche
          Regelungen für Rechtsanwälte für die Anwälte von TUR ADVOCATES Rechtsanwaltsgesellschaft
          mbH:</strong><br/>Bundesrechtsanwaltsordnung (BRAO)<br/>Rechtsanwaltsvergütungsgesetz
        (RVG)<br/>Berufsordnung (BORA)<br/>Fachanwaltsordnung (FAO)<br/>Berufsregeln der
        Rechtsanwälte der Europäischen Union (CCBE)<br/>Gesetz über die Tätigkeit europäischer
        Rechtsanwälte in Deutschland (EuRAG)<br/>Professional Code of Conduct for Barristers</p>
      <p><br/><strong>Mitgliedschaften:</strong><br/>South Eastern Circuit <br/>The European Circuit
        of the Bar <br/>The Honourable Society of Gray’s Inn <br/>Anwalt.de services
        AG<br/><br/><strong>Versicherung:</strong><br/><br/>TUR ADVOCATES Rechtsanwaltsgesellschaft
        mbH<br/>HDI Versicherung AG<br/>Vermögensschaden-Haftpflicht
        Nr.<br/>V-068-904-702-1<br/><br/>Rechtsanwalt Anthony Tur<br/>HDI Versicherung AG
        <br/>Vermögensschaden-Haftpflichtversicherung
        Nr.<br/>V-072-768-206-6<br/>&nbsp;<br/><strong>Online-Streitbeilegungsplattform und
          Verbraucherstreitbeilegung:</strong></p>
      <p><br/>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit.
        Die TUR ADVOCATES Rechtsanwaltsgesellschaft mbH nimmt nicht an Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teil.<br/>&nbsp;<br/>&nbsp;<br/>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Imprint',
};
</script>

<style scoped>

</style>
