<template>
<section class="h-screen w-full flex flex-col justify-center items-center bg-secondary-dark">
  <div class="relative">
    <h1 class="text-9xl font-extrabold text-white tracking-widest">404</h1>
    <div class="bg-primary-light px-2 text-md rounded transform rotate-12 absolute text-white
                rounded-lg top-16 left-16">
      {{ $t("404.notFound") }}
    </div>
  </div>
  <router-link to="/" class="mt-5">
    <button class="uppercase py-2 px-4 rounded-lg bg-transparent border-2 border-primary-light
                    text-primary-light dark:text-white hover:bg-primary-light hover:text-white
                    text-md">
      {{ $t("404.backHome") }}</button>
  </router-link>
</section>
</template>

<script>
export default {
  name: '404',
};
</script>

<style scoped>

</style>
